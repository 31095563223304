import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
import OurHistory from '../components/AboutUs/OurHistory'
import WhyChooseUs from '../components/AboutUs/PMV'
import TeamMembers from '../components/SEOAgency/TeamMembers'
 
const AboutUs = () => {
    return (
        <Layout>
            <Navbar />
            <div className="pt-100"></div>
            <AboutUsContent />
            <OurHistory />
            <WhyChooseUs />
            <TeamMembers />
            <Footer />
        </Layout>
    );
}

export default AboutUs;