import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <h2>Purpose, Mission & Vision  </h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    {/* <h3>Data for All Your People</h3> */}
                                    <p><span className="black-text">Purpose</span> - embrace the industry wide transition to a cloud-based workspace, enhance employees experience and to future- proof work environments.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    {/* <h3>A New Breed of AI</h3> */}
                                    <p><span className="black-text">Mission</span> - follow the latest trends in technology, to make strategic investment in research and development, as well as to make sure of continuous up-skilling of all our employees.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    {/* <h3>Analytics Business</h3> */}
                                    <p><span className="black-text">Vision</span> - expand globally, with a major focus on continuous improvement of our products and services as well as growth and development of the company – together with its partners, employees and users. All with an aim of  becoming a household name.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <StaticImage src={'../../assets/images/about/pmv-img.png'} 
                                        alt="Teammates collaborating." 
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs