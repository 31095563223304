import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <StaticImage src={'../../assets/images/about/main.png'} 
                                        alt="Group of people standing next to eachother." 
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"
                                        loading="eager"/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>About Us</h2>
                                <p className="text-justify">Tafoma is a team of experienced professionals guided by the same goal: to embrace the new and evolving digital workplace with an <span className="black-text same-line">all-in-one solution</span> to teamwork and project management problems.</p>
                                
                                <p className="text-justify">Inspired by the fast-paced changes of the modern world, the need for transparency, demands of efficient and productive collaborative workspaces, and our own experience in the field; we created Tafoma.</p>

                                {/* <p className="text-justify">Now, we bring you our vision. <span className="black-text">Let us reinvent the working environment with you! </span></p> */}
                                <p className="text-justify">Now, we bring you our vision. <span className="black-text">Let's reinvent the working environment together! </span></p>
                                <Link to="/blog/who-is-behind-tafoma-meet-the-team" className="default-btn">
                                    More About Us<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUsContent;